<!-- ./frontend/src/views/NotificationsPage.vue -->
<template>
  <v-container>
    <h1 class="text-h4 mb-6">Notifications</h1>
    <v-list v-if="notifications.length > 0">
      <v-list-item
        v-for="notification in notifications"
        :key="notification._id"
        :class="{ 'unread': !notification.isRead }"
      >
        <v-list-item-content>
          <v-list-item-title>{{ notification.subject }}</v-list-item-title>
          <v-list-item-subtitle>{{ notification.content }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ formatDate(notification.createdAt) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="markAsRead(notification._id)"
            v-if="!notification.isRead"
          >
            <v-icon>mdi-email-open</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-alert v-else type="info">You have no notifications.</v-alert>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'NotificationsPage',
  setup() {
    const store = useStore();
    const notifications = ref([]);

    const fetchNotifications = async () => {
      try {
        const response = await store.dispatch('fetchNotifications');
        notifications.value = response;
        console.log('Fetched notifications:', notifications.value);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    const markAsRead = async (id) => {
      try {
        await store.dispatch('markNotificationAsRead', id);
        await fetchNotifications();
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    onMounted(fetchNotifications);

    return {
      notifications,
      markAsRead,
      formatDate,
    };
  },
};
</script>

<style scoped>
.unread {
  background-color: #e3f2fd;
}
</style>
