<template>
  <v-container>
    <h1>Admin: Locations</h1>
    <v-btn color="primary" @click="openAddDialog" class="mb-4">Add New Location</v-btn>
    <v-data-table
      :headers="headers"
      :items="locations"
      :items-per-page="10"
      class="elevation-1"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn small color="primary" @click="editLocation(item)">Edit</v-btn>
        <v-btn small color="error" @click="deleteLocation(item._id)">Delete</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="saveLocation" ref="form">
            <v-text-field
              v-model="editedLocation.name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedLocation.province"
              label="Province"
              :rules="[v => !!v || 'Province is required']"
              required
            ></v-text-field>
            <v-textarea
              v-model="editedLocation.description"
              label="Description"
            ></v-textarea>
            <v-switch
              v-model="editedLocation.isActive"
              label="Active"
            ></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveLocation">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AdminLocationsView',
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Province', value: 'province' },
        { text: 'Description', value: 'description' },
        { text: 'Active', value: 'isActive' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedLocation: {
        name: '',
        province: '',
        description: '',
        isActive: true
      },
      defaultLocation: {
        name: '',
        province: '',
        description: '',
        isActive: true
      }
    };
  },
  computed: {
    ...mapState(['locations']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Location' : 'Edit Location';
    }
  },
  methods: {
    ...mapActions(['fetchLocations', 'createLocation', 'updateLocation', 'deleteLocation']),
    editLocation(location) {
      this.editedIndex = this.locations.indexOf(location);
      this.editedLocation = Object.assign({}, location);
      this.dialog = true;
    },
    openAddDialog() {
      this.editedIndex = -1;
      this.editedLocation = Object.assign({}, this.defaultLocation);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedLocation = Object.assign({}, this.defaultLocation);
        this.editedIndex = -1;
      });
    },
    async saveLocation() {
      if (this.$refs.form.validate()) {
        try {
          if (this.editedIndex > -1) {
            await this.updateLocation(this.editedLocation);
          } else {
            await this.createLocation(this.editedLocation);
          }
          this.closeDialog();
          await this.fetchLocations();
        } catch (error) {
          console.error('Error saving location:', error);
        }
      }
    }
  },
  created() {
    this.fetchLocations();
  }
};
</script>
