import axiosInstance from '../../utils/axios';

const state = {
  addresses: []
};

const mutations = {
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses;
  },
  ADD_ADDRESS(state, address) {
    state.addresses.push(address);
    if (address.isPreferred) {
      state.addresses.forEach(addr => {
        if (addr._id !== address._id) {
          addr.isPreferred = false;
        }
      });
    }
  },
  UPDATE_ADDRESS(state, { index, address }) {
    state.addresses.splice(index, 1, address);
  },
  REMOVE_ADDRESS(state, index) {
    state.addresses.splice(index, 1);
  }
};

const actions = {
  async fetchAddresses({ commit }) {
    try {
      const response = await axiosInstance.get('/api/addresses');
      commit('SET_ADDRESSES', response.data);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      throw error;
    }
  },
  async addAddress({ commit }, address) {
    try {
      const response = await axiosInstance.post('/api/addresses', address);
      commit('ADD_ADDRESS', response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding address:', error);
      throw error;
    }
  },
  async updateAddress({ commit }, { addressId, address }) {
    try {
      const response = await axiosInstance.put(`/api/addresses/${addressId}`, address);
      commit('SET_ADDRESSES', response.data);
    } catch (error) {
      console.error('Error updating address:', error);
      throw error;
    }
  },
  async removeAddress({ commit }, addressId) {
    try {
      const response = await axiosInstance.delete(`/api/addresses/${addressId}`);
      commit('SET_ADDRESSES', response.data);
    } catch (error) {
      console.error('Error removing address:', error);
      throw error;
    }
  },
  async setPreferredAddress({ dispatch }, addressId) {
    try {
      await axiosInstance.put(`/api/addresses/${addressId}/preferred`);
      await dispatch('fetchAddresses');
    } catch (error) {
      console.error('Error setting preferred address:', error);
      throw error;
    }
  }
};

const getters = {
  getAddresses: (state) => state.addresses,
  getPreferredAddress: (state) => state.addresses.find(address => address.isPreferred)
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
