// ./frontend/src/router/index.js

import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Import components
import Home from '../views/HomePage.vue'
import MeatPage from '../views/MeatPage.vue'
import BulkMeatPage from '../views/BulkMeatPage.vue'
import CheckoutPage from '../views/CheckoutPage.vue'
import ComingSoon from '../views/ComingSoon.vue'
import BulkOrderLanding from '../views/BulkOrderLanding.vue'
import BulkOrderInfo from '../views/BulkOrderInfo.vue'
import InvitePage from '../views/InvitePage.vue'
import AdminBulkOrdersView from '../views/AdminBulkOrdersView.vue'
import AdminUsersView from '../views/AdminUsersView.vue'
import AdminSellersView from '../views/AdminSellersView.vue'
import AdminBulkMeatProductsView from '../views/AdminBulkMeatProductsView.vue'
import AdminLocationsView from '../views/AdminLocationsView.vue'
import AdminProductsView from '../views/AdminProductsView.vue'
import AdminCategoriesView from '../views/AdminCategoriesView.vue'
import AdminSearchHistory from '../views/AdminSearchHistory.vue'
import AdminPremiumSubscriptionsView from '../views/AdminPremiumSubscriptionsView.vue'
import ProductOverviewPage from '../views/ProductOverviewPage.vue'
import AdminRecommendationsPage from '../views/AdminRecommendationsPage.vue'
import AdminOrdersView from '../views/AdminOrdersView.vue'
import OrdersPage from '../views/OrdersPage.vue'
import AccountPage from '../views/AccountPage.vue'
import CartPage from '../views/CartPage.vue'
import SavedItemsPage from '../views/SavedItemsPage.vue'
import SearchPage from '../views/SearchPage.vue'
import SellerPage from '../views/SellerPage.vue'
import SavedRecommendedItemsPage from '../views/SavedRecommendedItemsPage.vue'
import SubscriptionDetailsPage from '../views/SubscriptionDetailsPage.vue'
import NotificationsPage from '../views/NotificationsPage.vue'
import PrivacyPolicyPage from '../views/PrivacyPolicyPage.vue'
import TermsOfServicePage from '../views/TermsOfServicePage.vue'
import ResetPasswordPage from '../views/ResetPasswordPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/subscription',
    name: 'SubscriptionDetails',
    component: SubscriptionDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: NotificationsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/seller-info',
    name: 'SellerInfo',
    component: () => import('../views/SellerInfoPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/seller/:url',
    name: 'SellerPage',
    component: SellerPage,
    props: true
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchPage
  },
  {
    path: '/cart',
    name: 'Cart',
    component: CartPage
  },
  {
    path: '/saved-recommended-items',
    name: 'SavedRecommendedItems',
    component: SavedRecommendedItemsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/meat',
    name: 'Meat',
    component: MeatPage,
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProducts', { category: 'Meat' }).then(() => next())
    }
  },
  {
    path: '/bulk-meat',
    name: 'BulkMeat',
    component: BulkMeatPage,
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchBulkOrders', { category: 'Meat' }).then(() => next())
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutPage
  },
  {
    path: '/order-confirmation/:orderId',
    name: 'OrderConfirmation',
    component: () => import('../views/OrderConfirmationPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/health-practitioners',
    name: 'HealthPractitioners',
    component: ComingSoon
  },
  {
    path: '/pyo',
    name: 'PickYourOwn',
    component: ComingSoon
  },
  {
    path: '/farm-tours',
    name: 'FarmTours',
    component: ComingSoon
  },
  {
    path: '/farmers-markets',
    name: 'FarmersMarkets',
    component: ComingSoon
  },
  {
    path: '/bulk-order-landing/:inviteCode',
    name: 'BulkOrderLanding',
    component: BulkOrderLanding,
    props: true
  },
  {
    path: '/bulk-order-info/:id',
    name: 'BulkOrderInfo',
    component: BulkOrderInfo,
    props: true
  },
  {
    path: '/invite/:id',
    name: 'Invite',
    component: InvitePage,
    props: true
  },
  {
    path: '/:category/:id',
    name: 'ProductOverview',
    component: ProductOverviewPage,
    props: true
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyPage
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfServicePage
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPasswordPage
  },
  {
    path: '/admin/premium-subscriptions',
    name: 'AdminPremiumSubscriptions',
    component: AdminPremiumSubscriptionsView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/bulk-orders',
    name: 'AdminBulkOrders',
    component: AdminBulkOrdersView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: AdminUsersView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/sellers',
    name: 'AdminSellers',
    component: AdminSellersView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/bulk-meat-products',
    name: 'AdminBulkMeatProducts',
    component: AdminBulkMeatProductsView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/locations',
    name: 'AdminLocations',
    component: AdminLocationsView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/products',
    name: 'AdminProducts',
    component: AdminProductsView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/orders',
    name: 'AdminOrders',
    component: AdminOrdersView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/search-history',
    name: 'AdminSearchHistory',
    component: AdminSearchHistory,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/categories',
    name: 'AdminCategories',
    component: AdminCategoriesView,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/recommendations',
    name: 'AdminRecommendations',
    component: AdminRecommendationsPage,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/search-history',
    name: 'AdminSearchHistory',
    component: AdminSearchHistory,
    meta: { requiresAdmin: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAdmin) {
    if (store.getters.isAdmin) {
      next()
    } else {
      next({ name: 'Home' })
    }
  } else {
    next()
  }
})

export default router
