<!-- ./frontend/src/views/SearchPage.vue -->
<template>
  <v-container class="pa-4" fluid>
    <v-row>
      <!-- Left drawer -->
      <v-navigation-drawer permanent>
        <v-list>
          <v-list-subheader>Categories</v-list-subheader>
          <v-list-group
            v-for="section in categoryTree"
            :key="section.name"
            :value="section.name"
          >
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="section.name" @click="filterBySection(section.name)"></v-list-item>
            </template>

            <v-list-group
              v-for="category in section.categories"
              :key="category.name"
              :value="category.name"
              sub-group
            >
              <template #activator="{ props }">
                <v-list-item v-bind="props" :title="category.name" @click="filterByCategory(category.name)"></v-list-item>
              </template>

              <v-list-item
                v-for="subcategory in category.subcategories"
                :key="subcategory"
                :title="subcategory"
                link
                @click="filterBySubcategory(subcategory)"
              ></v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>

      <!-- Main content -->
      <v-col fluid>
        <!-- Quick filters -->
        <v-chip-group>
          <v-select
            v-model="selectedSeller"
            :items="sellers"
            label="Seller"
            item-title="name"
            item-value="id"
            clearable
            @update:model-value="applyFilters"
            style="max-width: 200px;"
          ></v-select>
          <v-select
            v-model="selectedOptions"
            :items="options"
            label="Options"
            multiple
            chips
            clearable
            @update:model-value="applyFilters"
            style="max-width: 200px;"
          ></v-select>
        </v-chip-group>

        <!-- Active filters -->
        <v-chip-group>
          <v-chip
            v-for="filter in activeFilters"
            :key="filter.type + filter.value"
            closable
            @click:close="removeFilter(filter)"
          >
            {{ filter.type }}: {{ filter.value }}
          </v-chip>
        </v-chip-group>

        <!-- Sort dropdown -->
        <v-select
          v-model="sortBy"
          :items="sortOptions"
          label="Sort by"
          item-title="text"
          item-value="value"
          dense
          outlined
          class="mb-4"
          style="max-width: 200px;"
        ></v-select>

        <!-- Search results -->
        <template v-if="isViewingAll">
          <h2 class="mb-4">All Products</h2>
          <v-row>
            <v-col v-for="product in allProducts" :key="product._id" cols="12" sm="6" md="4">
              <ProductCard
                :product="product"
                :cart-quantity="cartQuantities[product._id] || 0"
                @navigate-to-product="navigateToProduct"
                @add-to-cart="addToCart"
                @increase-quantity="increaseQuantity"
                @decrease-quantity="decreaseQuantity"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <template v-if="filteredSearchResults.length > 0">
            <h2 class="mb-4">Search Results for "{{ searchQuery }}"</h2>
            <v-row>
              <v-col v-for="product in filteredSearchResults" :key="product._id" cols="12" sm="6" md="4">
                <ProductCard
                  :product="product"
                  :cart-quantity="cartQuantities[product._id] || 0"
                  @navigate-to-product="navigateToProduct"
                  @add-to-cart="addToCart"
                  @increase-quantity="increaseQuantity"
                  @decrease-quantity="decreaseQuantity"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <p>No results found for "{{ searchQuery }}"</p>
          </template>
          
          <h2 class="mt-8 mb-4">All Products</h2>
          <v-row>
            <v-col v-for="product in filteredAllProducts" :key="product._id" cols="12" sm="6" md="4">
              <ProductCard
                :product="product"
                :cart-quantity="cartQuantities[product._id] || 0"
                @navigate-to-product="navigateToProduct"
                @add-to-cart="addToCart"
                @increase-quantity="increaseQuantity"
                @decrease-quantity="decreaseQuantity"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import ProductCard from '@/components/ProductCard.vue'

export default {
  name: 'SearchPage',
  components: {
    ProductCard
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    
    const searchQuery = ref('')
    const isViewingAll = computed(() => route.query.viewAll === 'true')
    const searchResults = computed(() => store.state.searchResults)
    const allProducts = computed(() => store.state.products)
    
    const categoryTree = computed(() => store.state.categoryTree)

    const sortBy = ref('relevance')
    const sortOptions = [
      { text: 'Relevance', value: 'relevance' },
      { text: 'Price: Low to High', value: 'price_asc' },
      { text: 'Price: High to Low', value: 'price_desc' }
    ]

    const selectedSeller = ref(null)
    const selectedOptions = ref([])
    const activeFilters = ref([])

    const sellers = computed(() => {
      const uniqueSellers = new Set()
      allProducts.value.forEach(product => {
        if (product.seller && product.seller.name) uniqueSellers.add(product.seller.name)
      })
      return Array.from(uniqueSellers).map(name => ({ name, id: name }))
    })

    const options = computed(() => {
      const allOptions = new Set()
      allProducts.value.forEach(product => {
        if (product.options) {
          product.options.forEach(option => allOptions.add(option.option))
        }
      })
      return Array.from(allOptions)
    })

    const filteredSearchResults = computed(() => {
      return applyFiltersToProducts(searchResults.value)
    })

    const filteredAllProducts = computed(() => {
      const searchResultIds = new Set(searchResults.value.map(product => product._id))
      return applyFiltersToProducts(allProducts.value.filter(product => !searchResultIds.has(product._id)))
    })

    const applyFiltersToProducts = (products) => {
      let filtered = products.filter(product => 
        (!selectedSeller.value || product.seller.name === selectedSeller.value) &&
        (selectedOptions.value.length === 0 || product.options.some(option => selectedOptions.value.includes(option.option))) &&
        activeFilters.value.every(filter => {
          switch (filter.type) {
            case 'Section':
              return product.category && categoryTree.value.find(section => 
                section.name === filter.value && section.categories.some(cat => cat.name === product.category)
              )
            case 'Category':
              return product.category === filter.value
            case 'Subcategory':
              return product.subcategory === filter.value
            default:
              return true
          }
        })
      )

      if (sortBy.value === 'price_asc') {
        filtered.sort((a, b) => (a.weightAdjusted ? a.pricePerMeasure : a.price) - (b.weightAdjusted ? b.pricePerMeasure : b.price))
      } else if (sortBy.value === 'price_desc') {
        filtered.sort((a, b) => (b.weightAdjusted ? b.pricePerMeasure : b.price) - (a.weightAdjusted ? a.pricePerMeasure : a.price))
      }

      return filtered
    }

    const performSearch = async () => {
      console.log('performSearch called in SearchPage.vue')
      console.log('searchQuery:', searchQuery.value)
      console.log('isViewingAll:', isViewingAll.value)
      if (isViewingAll.value) {
        await store.dispatch('fetchProducts')
        await store.dispatch('saveSearchQuery', 'View All Products')
      } else if (searchQuery.value) {
        await store.dispatch('searchProducts', searchQuery.value)
        await store.dispatch('saveSearchQuery', searchQuery.value)
      }
    }

    const applyFilters = () => {
      // This function is called when filters are changed
      // The computed properties will update automatically
    }

    const filterBySection = (section) => {
      activeFilters.value = activeFilters.value.filter(f => f.type !== 'Section')
      activeFilters.value.push({ type: 'Section', value: section })
    }

    const filterByCategory = (category) => {
      activeFilters.value = activeFilters.value.filter(f => f.type !== 'Category')
      activeFilters.value.push({ type: 'Category', value: category })
    }

    const filterBySubcategory = (subcategory) => {
      activeFilters.value = activeFilters.value.filter(f => f.type !== 'Subcategory')
      activeFilters.value.push({ type: 'Subcategory', value: subcategory })
    }

    const removeFilter = (filter) => {
      activeFilters.value = activeFilters.value.filter(f => f !== filter)
    }

    const cartQuantities = computed(() => {
      const quantities = {}
      store.state.cart.forEach(item => {
        quantities[item._id] = item.quantity
      })
      return quantities
    })

    const addToCart = (product) => {
      store.dispatch('addToCart', product)
    }

    const increaseQuantity = (product) => {
      store.dispatch('updateCartItemQuantity', { 
        productId: product._id, 
        quantity: (cartQuantities.value[product._id] || 0) + 1 
      })
    }

    const decreaseQuantity = (product) => {
      const currentQuantity = cartQuantities.value[product._id] || 0
      if (currentQuantity > 0) {
        store.dispatch('updateCartItemQuantity', { 
          productId: product._id, 
          quantity: currentQuantity - 1 
        })
      }
    }

    const navigateToProduct = (product) => {
      router.push(`/${product.category.toLowerCase()}/${product._id}`)
    }

    onMounted(() => {
      console.log('SearchPage mounted')
      searchQuery.value = route.query.q || ''
      performSearch()
      store.dispatch('fetchCategories')
      store.dispatch('fetchProducts')
    })

    watch(() => route.query, (newQuery) => {
      console.log('Watch triggered in SearchPage')
      console.log('New query:', newQuery)
      searchQuery.value = newQuery.q || ''
      performSearch()
    })

    return {
      searchQuery,
      isViewingAll,
      searchResults,
      allProducts,
      categoryTree,
      sortBy,
      sortOptions,
      filteredSearchResults,
      filteredAllProducts,
      selectedSeller,
      selectedOptions,
      sellers,
      options,
      activeFilters,
      applyFilters,
      filterBySection,
      filterByCategory,
      filterBySubcategory,
      removeFilter,
      addToCart,
      cartQuantities,
      increaseQuantity,
      decreaseQuantity,
      navigateToProduct,
    }
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
