<!-- ./frontend/src/App.vue -->
<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app width="240">
      <!-- Navigation drawer content remains unchanged -->
    </v-navigation-drawer>

    <v-app-bar :color="'#FFFFFF'" app flat>
      <div class="d-flex align-center justify-space-between w-100">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          <router-link to="/" class="text-decoration-none d-flex align-center">
            <v-img
              :src="logoLight"
              :width="140"
              :height="38"
              contain
              class="mr-2"
              @load="onLogoLoad"
              @error="onLogoError"
            ></v-img>
          </router-link>
        </div>

        <div class="d-none d-sm-flex align-center flex-grow-1 mx-4">
          <v-form @submit.prevent="performSearch" class="d-flex align-center search-form">
            <v-text-field
              v-model="searchQuery"
              bg-color="transparent"
              density="compact"
              flat
              hide-details
              label="Search"
              single-line
              variant="outlined"
              class="custom-search-input"
            >
              <template #append-inner>
                <v-btn
                  icon
                  variant="text"
                  color="surface-light"
                  class="search-icon-btn"
                  @click="performSearch"
                >
                  <v-icon color="black">mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </div>

        <div class="d-flex align-center">
          <v-menu v-if="isAdmin" open-on-hover offset-y class="d-none d-sm-block">
            <!-- Admin menu content remains unchanged -->
          </v-menu>
          
          <v-menu v-if="isSeller" offset-y class="d-none d-sm-block">
            <!-- Seller menu content remains unchanged -->
          </v-menu>

          <v-menu offset-y class="d-none d-sm-block">
            <!-- Income menu content remains unchanged -->
          </v-menu>

          <v-btn icon @click="goToNotifications" class="d-none d-sm-flex">
            <v-icon color="black">mdi-bell-outline</v-icon>
            <v-badge
              v-if="unreadNotificationsCount > 0"
              :content="unreadNotificationsCount"
              color="error"
            ></v-badge>
          </v-btn>

          <v-btn icon @click="goToCart">
            <v-icon color="black">mdi-cart-outline</v-icon>
            <v-badge
              v-if="cartItemCount > 0"
              :content="cartItemCount"
              color="error"
            ></v-badge>
          </v-btn>

          <AccountMenu class="d-none d-sm-flex" />
        </div>
      </div>
    </v-app-bar>

    <!-- Mobile search bar -->
    <v-toolbar v-if="$vuetify.display.smAndDown" color="grey lighten-4" flat dense>
      <v-text-field
        v-model="searchQuery"
        bg-color="transparent"
        density="compact"
        flat
        hide-details
        label="Search"
        single-line
        variant="outlined"
        class="custom-search-input"
        @keyup.enter="performSearch"
      >
        <template #append-inner>
          <v-btn
            icon
            variant="text"
            color="surface-light"
            class="search-icon-btn"
            @click="performSearch"
          >
            <v-icon color="black">mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-toolbar>

    <!-- Mobile bottom navigation -->
    <v-bottom-navigation v-model="bottomNav" grow class="d-sm-none">
      <v-btn value="home" @click="goToHome">
        <v-icon>mdi-home</v-icon>
        <span>Home</span>
      </v-btn>
      <v-btn value="products" @click="goToProducts">
        <v-icon>mdi-shopping</v-icon>
        <span>Products</span>
      </v-btn>
      <v-btn value="income" @click="goToIncome">
        <v-icon>mdi-currency-usd</v-icon>
        <span>Income</span>
      </v-btn>
      <v-btn value="account" @click="goToProfile">
        <v-icon>mdi-account</v-icon>
        <span>Account</span>
      </v-btn>
    </v-bottom-navigation>

    <v-main>
      <v-container class="py-8 px-6" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app :color="secondaryColor" class="d-sm-flex d-none">
      <span>&copy; {{ new Date().getFullYear() }} Loquo - Local. Natural. Connected.</span>
    </v-footer>

    <v-snackbar v-model="snackbar" :timeout="2000" color="success">
      {{ snackbarText }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AccountMenu from '@/components/AccountMenu.vue'
import logoLight from '@/assets/logo.png'

export default {
  components: {
    AccountMenu,
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const drawer = ref(false)
    const snackbar = ref(false)
    const snackbarText = ref('')
    const bottomNav = ref('home')

    const primaryColor = "#623E2A"
    const secondaryColor = "#658D8B"

    const adminItems = [
      { title: 'Categories', to: '/admin/categories' },
      { title: 'Products', to: '/admin/products' },
      { title: 'Orders', to: '/admin/orders' },
      { title: 'Bulk Meat Products', to: '/admin/bulk-meat-products' },
      { title: 'Bulk Orders', to: '/admin/bulk-orders' },
      { title: 'Sellers', to: '/admin/sellers' },
      { title: 'Locations', to: '/admin/locations' },
      { title: 'Users', to: '/admin/users' },
      { title: 'Recommendations', to: '/admin/recommendations' },
      { title: 'Search History', to: '/admin/search-history' }
    ]

    const incomeItems = [
      { title: 'Become a seller', icon: 'mdi-store-plus' },
      { title: 'Refer a seller', icon: 'mdi-account-multiple-plus' },
      { title: 'Refer a customer', icon: 'mdi-account-plus' },
      { title: 'Publish shopping lists', icon: 'mdi-clipboard-list' },
      { title: 'Publish cooking recipes', icon: 'mdi-book-open-variant' },
      { title: 'Publish homeopathy recipes', icon: 'mdi-bottle-tonic' },
    ]

    const sellerItems = computed(() => [
      { title: 'Your Seller Info', icon: 'mdi-information', to: '/seller-info' },
      { title: 'Your Inventory', icon: 'mdi-package-variant', to: '/seller-inventory' },
      { title: 'Your Recommendations', icon: 'mdi-star', to: '/seller-recommendations' },
      { title: 'Your Seller Page', icon: 'mdi-storefront', to: sellerPageUrl.value },
      { title: 'Your Seller Orders', icon: 'mdi-clipboard-text', to: '/seller-orders' },
      { title: 'Your Shipments', icon: 'mdi-truck-delivery', to: '/seller-shipments' },
    ])

    const sections = [
      {
        title: 'Food',
        items: [
          { title: 'Bulk meat', icon: 'mdi-food-steak', to: '/bulk-meat' },
          { title: 'Farm subscriptions', icon: 'mdi-basket', to: '/farm-subscriptions' },
          { title: 'Recipes', icon: 'mdi-book-open-variant', to: '/recipes' },
        ]
      },
      {
        title: 'Health',
        items: [
          { title: 'Naturopathy', icon: 'mdi-bottle-tonic', to: '/naturopathy' },
          { title: 'Health Practitioners', icon: 'mdi-account-tie', to: '/health-practitioners' },
        ]
      },
      {
        title: 'Pregnancy',
        items: [
          { title: 'Organic baby registry', icon: 'mdi-baby-carriage', to: '/pregnancy/baby-registry' },
          { title: 'Education', icon: 'mdi-school', to: '/pregnancy/education' },
          { title: 'Doulas', icon: 'mdi-human-female-female', to: '/pregnancy/doulas' },
          { title: 'Groups', icon: 'mdi-account-group', to: '/pregnancy/groups' },
        ]
      },
      {
        title: 'Connect',
        items: [
          { title: 'Pick your own food', icon: 'mdi-food-apple', to: '/pyo' },
          { title: 'Farm tours', icon: 'mdi-tractor', to: '/farm-tours' },
          { title: 'Farmers markets', icon: 'mdi-store', to: '/farmers-markets' },
          { title: 'Restaurants', icon: 'mdi-silverware-fork-knife', to: '/restaurants' },
        ]
      },
    ]

    const isAdmin = computed(() => store.getters['user/isAdmin'])
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn'])
    const user = computed(() => store.state.user.user)
    const isSeller = computed(() => user.value && user.value.isSeller)
    const userProfileImage = computed(() => store.getters['user/userProfileImage'])

    const cartItemCount = computed(() => store.getters.cartItemCount)

    const searchQuery = ref('')

    const performSearch = () => {
      if (searchQuery.value.trim()) {
        router.push({ path: '/search', query: { q: searchQuery.value.trim() } })
      }
    }

    const viewAll = () => {
      searchQuery.value = '';
      router.push({ path: '/search', query: { viewAll: 'true' } })
    }

    const navigateToDirectory = () => {
      router.push('/directory');
    }

    const unreadNotificationsCount = ref(0)

    const goToNotifications = () => {
      router.push('/notifications')
    }

    const fetchUnreadNotificationsCount = async () => {
      try {
        const count = await store.dispatch('fetchUnreadNotificationsCount')
        unreadNotificationsCount.value = count
      } catch (error) {
        console.error('Error fetching unread notifications count:', error)
      }
    }

    const onLogoLoad = () => {
      console.log('Logo loaded successfully');
    }

    const onLogoError = (error) => {
      console.error('Error loading logo:', error);
    }

    const sellerPageUrl = computed(() => {
      const seller = store.state.seller
      if (seller && seller.url) {
        return `/seller/${seller.url}`
      }
      return '/seller-info'
    })

    const goToCart = () => {
      router.push('/cart');
    };

    const goToHome = () => {
      router.push('/')
    }

    const goToProducts = () => {
      router.push('/search?viewAll=true')
    }

    const goToIncome = () => {
      // Implement the income page navigation or open income menu
      router.push('/income')
    }

    const goToProfile = () => {
      router.push('/account')
    }

    onMounted(() => {
      fetchUnreadNotificationsCount()
      console.log('App component mounted');
    })

    // Fetch unread notifications count every 5 minutes
    setInterval(fetchUnreadNotificationsCount, 5 * 60 * 1000)

    return {
      drawer,
      sections,
      primaryColor,
      secondaryColor,
      isAdmin,
      isLoggedIn,
      user,
      isSeller,
      userProfileImage,
      adminItems,
      incomeItems,
      sellerItems,
      cartItemCount,
      goToCart,
      searchQuery,
      performSearch,
      viewAll,
      navigateToDirectory,
      unreadNotificationsCount,
      goToNotifications,
      onLogoLoad,
      onLogoError,
      logoLight,
      sellerPageUrl,
      snackbar,
      snackbarText,
      bottomNav,
      goToHome,
      goToProducts,
      goToIncome,
      goToProfile,
    }
  }
}
</script>

<style scoped>
.nav-menu {
  width: 300px;
}
.nav-menu h2 {
  color: var(--v-primary-base);
}
.search-form {
  min-width: 100px;
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
}
.custom-search-input {
  border-radius: 9999px !important;
}
.custom-search-input ::v-deep .v-field__outline {
  color: black !important;
}
.custom-search-input ::v-deep .v-field__input {
  caret-color: black !important;
}
.search-icon-btn {
  margin-right: -12px !important;
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}
.view-all-btn {
  margin-left: 8px;
}
.v-list-item {
  min-height: 40px;
}
.v-list-item__prepend {
  margin-right: 16px;
}
.v-list-item__title {
  font-size: 14px;
}
.v-list-subheader {
  font-size: 14px;
  font-weight: bold;
  height: 36px;
}
.v-divider {
  margin: 8px 0;
}
.v-divider {
  margin: 8px 0;
}
.v-img {
  max-width: 140px;
  width: auto;
  height: auto;
}
.v-btn.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Styles for bottom navigation */
.v-bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

/* Adjust main content to account for bottom navigation on mobile */
@media (max-width: 600px) {
  .v-main {
    padding-bottom: 56px !important;
  }
  
  .v-app-bar {
    height: 56px !important;
  }

  .v-toolbar {
    padding: 0 16px;
  }

  .custom-search-input {
    width: 100%;
  }
}
</style>
