<template>
  <v-navigation-drawer permanent>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Hi, {{ user?.name || 'Guest' }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item to="/account">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Your Account</v-list-item-title>
        </v-list-item>

        <v-list-item to="/orders">
          <v-list-item-icon>
            <v-icon>mdi-truck-delivery</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Your Orders</v-list-item-title>
        </v-list-item>

        <v-list-item to="/bulk-orders">
          <v-list-item-icon>
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Your Bulk Orders/Subscriptions</v-list-item-title>
        </v-list-item>

        <v-list-item to="/saved-recommended-items">
          <v-list-item-icon>
            <v-icon>mdi-heart</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Your Saved/Recommended Items</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AccountManagementDrawer',
  setup() {
    const store = useStore();
    const selectedItem = ref(0);
    const user = computed(() => store.state.user);

    return {
      selectedItem,
      user,
    };
  },
};
</script>
