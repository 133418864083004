<!-- ./frontend/src/components/AccountMenu.vue -->
<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom end">
    <template #activator="{ props }">
      <v-btn icon v-bind="props">
        <v-avatar v-if="isLoggedIn && userProfileImage" size="32">
          <v-img :src="userProfileImage" alt="User Profile"></v-img>
        </v-avatar>
        <v-icon v-else :color="'#658D8B'" size="large">
          {{ isLoggedIn ? userInitialIcon : 'mdi-account-circle' }}
        </v-icon>
      </v-btn>
    </template>
    <v-card class="mx-auto account-menu-card" max-width="450" rounded="xl">
      <v-sheet class="pa-6 px-md-10 py-md-12">
        <h1 class="text-h4 font-weight-black mb-2">
          {{ isLoggedIn ? 'Account' : (showSignUp ? 'Sign Up' : (showForgotPassword ? 'Forgot Password' : 'Login')) }}
        </h1>
        <template v-if="!isLoggedIn && !showSignUp && !showForgotPassword">
          <div class="d-flex align-center justify-space-between text-body-1 text-sm-h6 font-weight-regular mb-8">
            Don't have an account yet?
            <v-btn class="text-none px-0 opacity-100" color="primary" :ripple="false" text="Sign Up" variant="plain" @click="showSignUp = true" />
          </div>
          <div>
            <v-label class="font-weight-bold">Email Address</v-label>
            <v-text-field v-model="loginEmail" color="primary" density="comfortable" placeholder="you@example.com" variant="outlined" />
          </div>
          <div>
            <v-label class="font-weight-bold">Password</v-label>
            <v-text-field v-model="loginPassword" color="primary" density="comfortable" persistent-counter placeholder="Enter your password" type="password" variant="outlined">
              <template #counter>
                <v-btn class="text-body-2 px-0 me-n8" color="primary" :ripple="false" size="small" text="Forgot Password?" variant="plain" @click="showForgotPassword = true" />
              </template>
            </v-text-field>
          </div>
          <div class="mb-6">
            <v-checkbox-btn v-model="rememberMe" class="ms-n3" color="primary" label="Remember me" />
          </div>
          <v-btn block class="text-none mb-4" color="surface-variant" flat height="56" rounded="lg" text="LOGIN" @click="login" />
          <v-divider class="mb-4">
            <span class="text-muted px-2">Or continue with</span>
          </v-divider>
          <div class="social-login-buttons">
            <GoogleLogin
              :callback="handleGoogleLogin"
            />
            <v-btn
              block
              class="facebook-btn"
              color="blue"
              variant="outlined"
              @click="handleFacebookLogin"
            >
              <v-icon start icon="mdi-facebook" class="mr-2"></v-icon>
              Facebook
            </v-btn>
          </div>
        </template>
        <template v-if="!isLoggedIn && showSignUp">
          <div class="d-flex align-center justify-space-between text-body-1 text-sm-h6 font-weight-regular mb-8">
            Already have an account?
            <v-btn class="text-none px-0 opacity-100" color="primary" :ripple="false" text="Login" variant="plain" @click="showSignUp = false" />
          </div>
          <div>
            <v-label class="font-weight-bold">Name</v-label>
            <v-text-field v-model="signupName" color="primary" density="comfortable" placeholder="Your name" variant="outlined" />
          </div>
          <div>
            <v-label class="font-weight-bold">Email Address</v-label>
            <v-text-field v-model="signupEmail" color="primary" density="comfortable" placeholder="you@example.com" variant="outlined" />
          </div>
          <div>
            <v-label class="font-weight-bold">Password</v-label>
            <v-text-field
              v-model="signupPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              color="primary"
              density="comfortable"
              placeholder="Enter your password"
              variant="outlined"
              @click:append="showPassword = !showPassword"
            />
          </div>
          <v-btn block class="text-none" color="surface-variant" flat height="56" rounded="lg" text="SIGN UP" @click="signup" />
        </template>
        <ForgotPassword v-if="!isLoggedIn && showForgotPassword" @back="showForgotPassword = false" />
        <template v-if="isLoggedIn">
          <v-list>
            <v-list-item @click="navigateTo('/account')">
              <template #prepend>
                <v-icon>mdi-account</v-icon>
              </template>
              <v-list-item-title>Your Account</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/orders')">
              <template #prepend>
                <v-icon>mdi-package-variant</v-icon>
              </template>
              <v-list-item-title>Your Orders</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/bulk-orders')">
              <template #prepend>
                <v-icon>mdi-package-variant-closed</v-icon>
              </template>
              <v-list-item-title>Your Bulk Orders/Subscriptions</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateTo('/saved-recommended-items')">
              <template #prepend>
                <v-icon>mdi-heart</v-icon>
              </template>
              <v-list-item-title>Your Saved/Recommended Items</v-list-item-title>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item class="mt-4">
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-btn block class="text-none mt-4" color="surface-variant" flat height="56" rounded="lg" text="LOGOUT" @click="logout" />
        </template>
      </v-sheet>
    </v-card>
  </v-menu>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GoogleLogin } from 'vue3-google-login'
import ForgotPassword from './ForgotPassword.vue'

export default {
  name: 'AccountMenu',
  components: {
    GoogleLogin,
    ForgotPassword
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const menu = ref(false)
    const showSignUp = ref(false)
    const showForgotPassword = ref(false)
    const loginEmail = ref('')
    const loginPassword = ref('')
    const signupName = ref('')
    const signupEmail = ref('')
    const signupPassword = ref('')
    const rememberMe = ref(false)
    const showPassword = ref(false)

    const isLoggedIn = computed(() => store.getters['user/isLoggedIn'])
    const user = computed(() => store.state.user.user)
    const userProfileImage = computed(() => store.getters['user/userProfileImage'])
    
    const seller = computed(() => store.state.seller)

    const userInitialIcon = computed(() => {
      if (!user.value) return 'mdi-account-circle';
      let initial = user.value.name.charAt(0).toUpperCase();
      if (user.value.isSeller && seller.value) {
        initial = seller.value.name.charAt(0).toUpperCase();
      }
      return `mdi-alpha-${initial.toLowerCase()}-circle`;
    });

    const login = async () => {
      try {
        await store.dispatch('user/login', {
          email: loginEmail.value,
          password: loginPassword.value,
          rememberMe: rememberMe.value
        })
        menu.value = false
        await store.dispatch('user/fetchUserDetails')
      } catch (error) {
        console.error('Login failed:', error)
        // Handle login error (e.g., show error message to user)
      }
    }

    const signup = async () => {
      try {
        await store.dispatch('user/signup', {
          name: signupName.value,
          email: signupEmail.value,
          password: signupPassword.value
        })
        menu.value = false
        showSignUp.value = false
        await store.dispatch('user/fetchUserDetails')
      } catch (error) {
        console.error('Signup failed:', error)
        // Handle signup error (e.g., show error message to user)
      }
    }

    const logout = async () => {
      try {
        await store.dispatch('user/logout')
        menu.value = false
        router.push('/')
      } catch (error) {
        console.error('Logout failed:', error)
        // Handle logout error
      }
    }

    const navigateTo = (route) => {
      router.push(route)
      menu.value = false
    }

    const handleGoogleLogin = async (response) => {
      try {
        await store.dispatch('user/googleLogin', response.credential)
        menu.value = false
        await store.dispatch('user/fetchUserDetails')
      } catch (error) {
        console.error('Google login failed:', error)
      }
    }

    const handleFacebookLogin = () => {
      if (window.FB) {
        window.FB.login(function(response) {
          if (response.authResponse) {
            window.FB.api('/me', { fields: 'name, email, picture' }, function(response) {
              store.dispatch('user/facebookLogin', response)
                .then(() => {
                  menu.value = false;
                  router.push('/');
                })
                .catch(error => {
                  console.error('Facebook login failed:', error);
                });
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        }, {scope: 'public_profile,email'});
      } else {
        console.error('Facebook SDK not loaded');
      }
    };

    return {
      menu,
      showSignUp,
      showForgotPassword,
      loginEmail,
      loginPassword,
      signupName,
      signupEmail,
      signupPassword,
      rememberMe,
      showPassword,
      isLoggedIn,
      user,
      seller,
      userInitialIcon,
      userProfileImage,
      login,
      signup,
      logout,
      navigateTo,
      handleGoogleLogin,
      handleFacebookLogin
    }
  }
}
</script>

<style scoped>
.account-menu-card {
  min-width: 300px;
}
.social-login-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.facebook-btn {
  height: 40px;
}
</style>
